import React, { useState } from "react"
import ReactPlayer from "react-player"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BigHeader from "../../components/bigHeader"

import t from "locale"

const OurProducts = ({ contentField }) => {
  const lang = "fr"
  const [modal, setModal] = useState(false)

  const seo = contentField.seo
  const image = contentField.featuredImage

  return (
    <Layout
      lang={lang}
      translationEN="/our-products/"
      translationIT="/it/i-nostri-prodotti/"
      translationDE="/de/produkte/"
      translationES="/es/nuestros-productos/"
    >
      {/* eslint-disable jsx-a11y/click-events-have-key-events */}
      {/* eslint-disable jsx-a11y/no-static-element-interactions */}
      <SEO
        title={seo.title}
        description={seo.metaDesc}
        image={image ? image.sourceUrl : "https://hiddenfjord.com/og-image.png"}
      />

      <BigHeader
        title="NOS PRODUITS"
        image={require("../../assets/images/our-products-back.jpg")}
      />

      <div className="main-section">
        <div className="text-container">
          <div
            className="inline-content"
            dangerouslySetInnerHTML={{
              __html: contentField.OurProductsACF.ourProductsPart1,
            }}
          />
          <a
            className="inline-link"
            href="/"
            onClick={e => {
              e.preventDefault()
              setModal(true)
            }}
          >
            {t("See video", lang)}
          </a>
        </div>
        <br />
        <div
          className="text-container"
          dangerouslySetInnerHTML={{
            __html: contentField.OurProductsACF.ourProductsPart2,
          }}
        />
        {modal && (
          <>
            <div className="subpage-modal">
              <div className="subpage-modal__wrapper">
                <button
                  className="subpage-modal__close"
                  onClick={e => {
                    setModal(false)
                  }}
                >
                  {t("Close", lang)}
                </button>
                <div className="embed-container">
                  <ReactPlayer
                    url="https://vimeo.com/747254874"
                    width="100%"
                    heigth="100%"
                    playing={modal === true ? true : false}
                    controls={true}
                    muted={false}
                  />
                </div>
              </div>
            </div>
            <div
              className="subpage-modal__overlay"
              onClick={e => {
                setModal(false)
              }}
            ></div>
          </>
        )}
      </div>
    </Layout>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        contentField: wpPage(id: { eq: "cG9zdDoxNzI2" }) {
          OurProductsACF {
            ourProductsPart1
            ourProductsPart2
            ourProductsVideoLink
          }
          seo {
            title
            metaDesc
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    `}
    render={data => <OurProducts {...data} />}
  />
)
